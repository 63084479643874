import moment from 'moment-timezone';
import { formatPrice } from './formatPrice';
export const rgbToHex = (rgb) => {
				
    const rgbArray = rgb.match(/\d+/g);
    if (!rgbArray) return '';

    return '#' + rgbArray.map(Number).map(val => {
      const hex = val.toString(16);
      return hex.length === 1 ? '0' + hex : hex;
    }).join('');

  };
export const HexToRGB = (hexColor) => {
      const hexWithoutHash = hexColor.replace('#', ''); // Remove the # character if present
      const red = parseInt(hexWithoutHash.substr(0, 2), 16); // Convert the red component
      const green = parseInt(hexWithoutHash.substr(2, 2), 16); // Convert the green component
      const blue = parseInt(hexWithoutHash.substr(4, 2), 16); // Convert the blue component
      return `rgb(${red}, ${green}, ${blue})`; // Return the RGB representation
}
export const RgbToRgba = (rgb,alpha) => {
        const rgbValues = rgb.match(/\d+/g); // Extract the RGB component values
        const red = rgbValues[0];
        const green = rgbValues[1];
        const blue = rgbValues[2];
        return `rgba(${red}, ${green}, ${blue}, ${alpha})`; // Return the RGBA representation
}
export const checkImgSrcExists = async (imgSrc) => {
      try {
            const response = await fetch(imgSrc);
            return response.ok;
          } catch (error) {
            console.log("error",error)
            return false;
          }
      // let isSrcExists = false;
      // if(imgSrc && imgSrc != ""){
           
      //       const img  = new Image();
      //       img.src = imgSrc;
      //       img.onload = function() {
      //             console.log("true image")
      //             isSrcExists =  true;
      //       };
            
      //       img.onerror = function() {
      //             console.log("false image")
      //             isSrcExists = false;
      //       };
      //   return isSrcExists;
      // }
      // return false;
}
export const contrastRatioValue = 1.6;
export const getColorFormat = (colorCode) => {
    // Regular expressions for matching hex, RGB, and RGBA formats
        const hexRegex = /^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/;
        const rgbRegex = /^rgb\(\s*\d+\s*,\s*\d+\s*,\s*\d+\s*\)$/;
        const rgbaRegex = /^rgba\(\s*\d+\s*,\s*\d+\s*,\s*\d+\s*,\s*[0-1]\.?\d*\s*\)$/;
        if (hexRegex.test(colorCode)) {
        return 'HEX';
        } else if (rgbRegex.test(colorCode)) {
        return 'RGB';
        } else if (rgbaRegex.test(colorCode)) {
        return 'RGBA';
        } else {
        return 'Unknown';
        }
  };
  const innerJoin = (arr1, arr2, key) => {
      return arr1.filter(obj1 => {
        return arr2.some(obj2 => obj1.id == obj2.id);
      });
    };
    export const calculateContrast = (backgroundColor, textColor) => {
      const getLuminance = (color) => {
        const rgb = parseInt(color.slice(1), 16); 
        const r = (rgb >> 16) & 0xff; 
        const g = (rgb >> 8) & 0xff; 
        const b = rgb & 0xff; 
        const sRGB = [r / 255, g / 255, b / 255];
    
        for (let i = 0; i < sRGB.length; i++) {
          if (sRGB[i] <= 0.03928) {
            sRGB[i] /= 12.92;
          } else {
            sRGB[i] = Math.pow((sRGB[i] + 0.055) / 1.055, 2.4);
          }
        }
    
        const luminance = 0.2126 * sRGB[0] + 0.7152 * sRGB[1] + 0.0722 * sRGB[2];
        return luminance;
      };
    
      
    const contrastRatio =
        getLuminance(backgroundColor) > getLuminance(textColor)
          ? (getLuminance(backgroundColor) + 0.05) / (getLuminance(textColor) + 0.05)
          : (getLuminance(textColor) + 0.05) / (getLuminance(backgroundColor) + 0.05);
    
      return contrastRatio;
    };

  export const getOutofStockStatus = (start_time,end_time) => {
    console.log('start_time::::::::',start_time);
    console.log('end_time::::::::',end_time);
    let isOutOfStack = false;
    const timezoneName = localStorage.getItem("zone_name");
     let newYorkTime = moment.tz(timezoneName);
     let formattedDateTime = newYorkTime.format('YYYY-MM-DD HH:mm');
     const currentDateTime = new Date(formattedDateTime);
     console.log('currentDateTime::::::::::::::::::::',currentDateTime);
     const pauseTime = new Date(start_time);
     const resumeTime = new Date(end_time);
     console.log('pauseTime::::::::::::::::',pauseTime);
     console.log('resumeTime::::::::::::::',resumeTime);
     if(pauseTime <= currentDateTime && resumeTime >=  currentDateTime){
      console.log('true::::::::::::::::::::::');
      isOutOfStack = true;
     }
     console.log('isOutOfStack::::::::::::::::',isOutOfStack);
     return isOutOfStack;
  }
    
   export const getTimeDifferenceHours = (givenDate) => {
    console.log('givenDate::::::::::',givenDate);
    console.log('storage::::::::::::',localStorage.getItem("rest_date_format"));
    var dateFormat = CheckValidDateFormat(localStorage.getItem("rest_date_format"));
     const timezoneName = localStorage.getItem("zone_name");
     console.log('zonaname::::::',timezoneName);
     const currentDateTimeInTimeZone = moment().tz(timezoneName).format(`${dateFormat.toUpperCase()} HH:mm:ss`);
     console.log('currentDateTimeInTimeZone:::::::::::::',currentDateTimeInTimeZone);
      const currentDate = new Date();
      const timeDifference = new Date(givenDate).getTime() - new Date(currentDateTimeInTimeZone).getTime();
      console.log('timeDifference::::::::::::::',timeDifference);
      const hoursDifference = Math.floor(timeDifference / (1000 * 60 * 60));
      const minutesDifference = Math.floor((timeDifference / (1000 * 60)) % 60);
      if(hoursDifference > 0){
            return `${hoursDifference.toString()} hrs ${minutesDifference} Mins`;
      }
      else if(hoursDifference < 0){
            return "0"
      }
      else{
            if(minutesDifference < 0){
                  return "0 Mins"
            }
            return `${minutesDifference} Mins`;
      }
}
      // return {
      //   hours: hoursDifference,
      //   minutes: minutesDifference
      // };

  export const getOrderTrackingStatusProperties = (order,orderStatusList = orderStatusList) => {
      const currentActiveOrder = orderStatusList.find(x => x.id == order.orders.orderstatus_id);
       let orderTrackingStatus = [];
       let isOrderStatusReached = false;
       if(currentActiveOrder){
            const orderStatusTrackingProperties = getOrderStatusArrangement(orderStatusList,order);
            orderStatusTrackingProperties.forEach((item, index) => {
                let statusItem = item;
                if(item.id === currentActiveOrder.id){
                  isOrderStatusReached = true;
                  statusItem["status_class"] = "current";
                  if(index === (orderStatusTrackingProperties.length - 1)){
                        statusItem["status_class"] = "success";
                  }
                }
                else{
                  if(!isOrderStatusReached){
                      statusItem["status_class"] = "success";
                  }
                  else{
                        statusItem["status_class"] = "";
                  }
                }
              orderTrackingStatus.push(item)
            });
       }
  return orderTrackingStatus;
  }
  export const getOrderStatusArrangement = (orderStatusList,currentOrder) => {
      const currentActiveOrder = orderStatusList.find(x => x.id == currentOrder.orders.orderstatus_id);
      let orderStatusForType = [];
          if(currentOrder.orders.delivery_type == "1" || currentOrder.orders.delivery_type == "2"){
              orderStatusForType = orderDeliveryHierarchyStatus;
          }
          else{
             orderStatusForType = orderPickupHierarchyStatus;
          }
          orderStatusForType = innerJoin(orderStatusList,orderStatusForType,"id");
          if(orderStatusForType && orderStatusForType.length > 0){
            orderStatusForType = orderStatusForType.sort((a,b) => a.id - b.id);
          }
      return orderStatusForType;
  }
export const getTimeFromDate = (date,format = "en-US")=>{
      const current = new Date(date);
      const time = current.toLocaleTimeString(format, {
            hour: "2-digit",
            minute: "2-digit",
            hour12: true
       });
       return time;
  }
  export const getFirstSliderAsImage = (sliders) => {
    const validExtensions = ['.webp', '.jpeg', '.jpg', '.png'];
		let defaultSliders = null;
		for (let imageSrc of sliders) {
			if(imageSrc && imageSrc.slider_path && imageSrc.slider_path != ""){
				const lowerCaseSrc = imageSrc.slider_path.toLowerCase();
			if (validExtensions.some(extension => lowerCaseSrc.endsWith(extension))) {
				defaultSliders = imageSrc.slider_path;
				return defaultSliders;
			  }
			}
			 
		 }
		return defaultSliders; // Return null if no image is found
	};
  const orderCancelledStatus = [
      { sort:1,name:"Pending",id:1},
      { sort:2,name:"Accepted",id:2},
      { sort:3,name:"Canceled",id:6}
  ]
  const orderPaymentFailedStatus = [
      { sort:1,name:"Pending",id:1},
      { sort:2,name:"Accepted",id:2},
      { sort:3,name:"Payment Failed",id:9}
  ]
  const orderPickupHierarchyStatus = [
      { sort:1,name:"Pending" ,id:1},
      { sort:2,name:"Accepted",id:2},
      { sort:3,name:"Being Prepared",id:3},
      { sort:4,name:"Ready",id:4},
      { sort:5,name:"Picked Up",id:8}
  ]
  const orderDeliveryHierarchyStatus = [
      { sort:1,name:"Order Pending",id:1},
      { sort:2,name:"Order Accepted",id:2},
      { sort:3,name:"Being Prepared",id:3},
      { sort:4,name:"Ready",id:4},
      // { sort:5,name:"Picked Up",id:8},
      { sort:6,name:"Out For Delivery",id:5},
      { sort:7,name:"Delivered",id:7}
  ]

  const orderStatusList =  [{"id":"1","name":"Pending","order_message":"Order Pending","description":null,"image":null,"display_order":null,"is_active":"1","created_at":null,"updated_at":null},
            
  {"id":"2","name":"Accepted","order_message":"Order Accepted","description":null,"image":null,"display_order":null,"is_active":"1","created_at":null,"updated_at":null},
  
  {"id":"3","name":"Being Prepared","order_message":"Order Prepared","description":null,"image":null,"display_order":null,"is_active":"1","created_at":null,"updated_at":null},
  
  {"id":"4","name":"Ready","order_message":"Order Ready To Pick Up","description":null,"image":null,"display_order":null,"is_active":"1","created_at":null,"updated_at":null},
  
  {"id":"5","name":"Out For Delivery","order_message":"Order On The Way","description":null,"image":null,"display_order":null,"is_active":"1","created_at":null,"updated_at":null},
  
  {"id":"6","name":"Canceled","order_message":"Cancelled","description":null,"image":null,"display_order":null,"is_active":"1","created_at":null,"updated_at":null},
  
  {"id":"7","name":"Delivered","order_message":"Order Delivered","description":null,"image":null,"display_order":null,"is_active":"1","created_at":null,"updated_at":null},
  
  {"id":"8","name":"Picked Up","order_message":"Order Picked Up","description":null,"image":null,"display_order":null,"is_active":"1","created_at":null,"updated_at":null},
  
  {"id":"9","name":"Payment Failed","order_message":"Payment Failed","description":null,"image":null,"display_order":null,"is_active":"1","created_at":null,"updated_at":null}
  ]
 const allPossibleFormats = {monthFormatMap:{possibleFormats:["m-d-y","mm-d-y","m-dd-y","mm-dd-y","mm-dd-yyyy","mm-dd-yy"],actualFormat:"MM-dd-yyyy"},dateFormatMap: {possibleFormats: ["d-m-y","dd-m-y","d-mm-y","dd-mm-y","dd-mm-yyyy","dd-mm-yy"],actualFormat:"dd-MM-yyyy"},yearFormatMap: {possibleFormats: ["yy-m-d","yy-mm-d","yy-m-dd"],actualFormat: "yyyy-MM-dd"}}
  export const CheckValidDateFormat = (dateFormat) => {
    for(const formatType in allPossibleFormats){
      const possibleFormat = allPossibleFormats[formatType].possibleFormats;
      const actualFormat  = allPossibleFormats[formatType].actualFormat;
      let isIncludeDate = possibleFormat.find(a=> a == dateFormat.toLowerCase());
      if(isIncludeDate){
        return actualFormat;
      } 
    }
    return dateFormat;
  }

  export const getValidDateFormatCreatedDate = (resDateFormat,createdAtDate) => {
    console.log('resDateFormat::::::::::::::::',resDateFormat);
    console.log('createdAtDate:::::::::::;;',createdAtDate);
    let date;
     const dateFormat = CheckValidDateFormat(resDateFormat);
     console.log('dateFormat::::::::::::',dateFormat);
     const dateString = createdAtDate.split(' ')[0];
     const isInFormat = isDateInFormat(dateString,dateFormat);
     console.log(isInFormat); 
     if(isInFormat){
       date = moment(createdAtDate, `${dateFormat.toUpperCase()} hh:mm A`).toDate();
     }else{
      const convertedDate = convertToFormat(createdAtDate,dateFormat); 
      console.log("convertedDate:::::::::", convertedDate);
      date = moment(convertedDate, `${dateFormat.toUpperCase()} hh:mm A`).toDate();
      console.log('convertedDate:::::::::::::',date);
     }
     console.log('date:::::::::::::',date);
     return date;
  }

  export const isDateInFormat = (dateString,dateFormat) => {
    console.log("dateString::::::::::::::",dateString);
    console.log("dateFormat:::::::",dateFormat);
    // Define the expected format
    const format = 'DD-MM-YY';
    
    // Parse the date string with the format
    const parsedDate = moment(dateString, format, true);
    
    // Check if the parsed date is valid
    return parsedDate.isValid();
};

export const convertToFormat = (dateString, dateFormat) => {
  // Define the input format expected
  const inputFormat = 'DD-MM-YYYY hh:mm A'; // Adjust this if input varies
  
  console.log('dateFormat1:::::', dateFormat.toUpperCase());
  console.log('dateFormat3::::', dateString);

  // Validate the input date format
  if (moment(dateString, inputFormat, true).isValid()) {
    // Convert the dateString to the desired format
    const convertedDate = moment(dateString, inputFormat).format(`${dateFormat.toUpperCase()} hh:mm A` );
    console.log('dateFormat2:::::', convertedDate);
    return convertedDate;
  } else {
    const convertedDate = moment(dateString).format(`${dateFormat.toUpperCase()} hh:mm A`);
    console.log('dateFormat2:::::',convertedDate);
    return convertedDate;
  }
};


export function getExpirationStatus(date, dateFormat = "MMMM DD, YYYY") {
  const now = moment().startOf('day'); // Set the current date to the start of the day
  const targetDate = moment(date).startOf('day'); // Set the target date to the start of the day

  const diffDays = targetDate.diff(now, 'days');
  const diffDaysAbs = Math.abs(diffDays);
  
  console.log('diffDays:::::::::::', diffDays);

  if (diffDays > 0) {
    if (diffDays <= 3) {
      return `Reward expires in ${diffDays} day${diffDays > 1 ? 's' : ''}`;
    } else if (diffDays > 9) {
      return `Valid until ${targetDate.format(dateFormat)}`;
    }
  } else if (diffDays < 0) {
    return `Expired ${diffDaysAbs} day${diffDaysAbs > 1 ? 's' : ''} ago`;
  } else {
    return `Reward expires today`;
  }

  return `Valid until ${targetDate.format(dateFormat)}`;
}

export function calculatePoints(cartValue, amountForPoints, pointsEarnedForAmount) {
  console.log('cartValue:::::::::::',cartValue);
  console.log('amountForPoints:::::::::::',amountForPoints);
  console.log('pointsEarnedForAmount::::::::',pointsEarnedForAmount);
  // Calculate the conversion rate
  const conversionRate = pointsEarnedForAmount / amountForPoints;
  // Calculate points earned
  const pointsEarned = cartValue * conversionRate;
  
  return parseInt(pointsEarned);
}

export function customDateTimeFormat(date){
  const formattedDate = moment(date).format('MMM DD, YYYY'); 
  const formattedTime = moment(date).format('hh:mm A'); 

  return {
    date: formattedDate,
    time: formattedTime
  };
}


export function convertDateFormat(dateString){
  // Create a new Date object from the input date string
  const date = new Date(dateString);

  // Extract the date components
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
  const day = String(date.getDate()).padStart(2, '0');

  // Extract the time components
  let hours = date.getHours();
  const minutes = String(date.getMinutes()).padStart(2, '0');
  const ampm = hours >= 12 ? 'pm' : 'am';
  hours = hours % 12;
  hours = hours ? hours : 12; // The hour '0' should be '12'
  hours = String(hours).padStart(2, '0');

  // Format the date and time
  const formattedDate = `${year}-${month}-${day} ${hours}:${minutes} ${ampm}`;

  return formattedDate;
};



